* {
    overflow: hidden;
    margin: 0px;
    padding: 0px;
    border: 0px;
}



.waterfallImage {
    height: 100%; 
    width: 100%;
}